// Dependencies
import { Box, colors, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import { alpha } from '@mui/material/styles';
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Components
import CircularLoader from '../../components/common/CircularLoader';

// Configs
import colorConfigs from '../../configs/colorConfigs';

// Services
import { LoadingButton } from '@mui/lab';
import { clearFeedbackMessage, getAllLocations, updateByLocation, updateUserError } from './states/actions';
import { isLoadingSelector, isUpdatingLocationSelector, locationsSelector, snackbarSelector } from './states/reducer';

const ManagePrint = () => {
    const { t } = useTranslation()
    const apiRef = useGridApiRef()
    const dispatch = useDispatch()
    
    const locations = useSelector(locationsSelector)
    const isLoading = useSelector(isLoadingSelector)
    const snackbar = useSelector(snackbarSelector)
    const isUpdatingLocation = useSelector(isUpdatingLocationSelector)
    
    const [searchText, setSearchText] = React.useState('')
    const filteredLocations = React.useMemo(() => {
        return locations.filter((loc) =>
            loc.name.toLowerCase().includes(searchText.toLowerCase())
        )
    }, [locations, searchText])


    const columns: GridColDef[] = [
        {
            field: 'location',
            headerName: t('utilities.manageLabel.table.header.main'), 
            headerClassName: 'table-header',
            renderCell(params: GridRenderCellParams) {
                const { row: { name } } = params

                return (
                    <div style={{ alignItems: 'center', display: 'flex' }}>
                        <Fragment>{name}</Fragment>
                    </div>
                )
            },
            flex: 1,
        },
        {
            field: 'action',
            headerName: t('utilities.manageLabel.table.header.actions.title'),
            align: 'center',
            headerAlign: 'center',
            headerClassName: 'table-header',
            renderCell(params: GridRenderCellParams) {
                const { row: { customPrint, ownerId } } = params
                const value = !!customPrint ? t('utilities.manageLabel.table.header.actions.disable') : t('utilities.manageLabel.table.header.actions.enable')
                const color = !! customPrint ? "error" : "success"
                const location = params.row
                return (
                    <div style={{ alignItems: 'center', display: 'flex', width: "100%", justifyContent: "center" }}>
                        <LoadingButton 
                            size='small'
                            variant='contained'
                            // onClick={() => dispatch(updateUser({ user: { ...user, customPrint: !customPrint }, userId }))}
                            onClick={() => dispatch(updateByLocation({ location: { ...location, customPrint: !customPrint } } ))}
                            sx={{ fontSize: '0.8vw' }}
                            color={color}
                            loading={isUpdatingLocation}
                        >
                            {value}
                        </LoadingButton>
                    </div>
                )
            },
            flex: 0.25,
            
        },
    ]

    React.useEffect(() => {
        dispatch(getAllLocations())
    }, [])

    const handleCloseSnackbar = () => dispatch(clearFeedbackMessage())

    const handleProcessRowUpdateError = React.useCallback(() => {
        dispatch(updateUserError({ children: t('utilities.manage.feedback.save.error'), severity: 'error' }))
    }, [dispatch, t]);

    if (isLoading) {
        return <CircularLoader />
    }
    
    return (
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          '& .table-header': {
            backgroundColor: colorConfigs.topbar.bg,
            fontSize: '1vw',
          },
          '& .disabled': {
            backgroundColor: alpha(colors.grey['300'], 0.4),
            fontSize: '0.9vw',
            padding: 2,
          },
          '& .row': {
            fontSize: '0.9vw',
            padding: 2,
          },
        }}
      >
        <TextField
          label="Search Location"
          variant="outlined"
          size="small"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ marginBottom: 2 }}
        />
        <DataGrid
          getRowId={(row) => {
            return row.id;
          }}
          rows={filteredLocations}
          columns={columns}
          getCellClassName={(params: GridCellParams<any, any, number>) => {
            // if (isProductDisabled(params, rows)) {
            //     return 'disabled'
            // }
            return 'row';
          }}
          showCellVerticalBorder
          columnThreshold={20}
          showColumnVerticalBorder
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          apiRef={apiRef}
          onProcessRowUpdateError={handleProcessRowUpdateError}
          autoPageSize
        />
        {!!snackbar && (
          <Snackbar
            open
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            onClose={handleCloseSnackbar}
            autoHideDuration={3000}
          >
            <Alert {...snackbar} onClose={handleCloseSnackbar} />
          </Snackbar>
        )}
      </Box>
    );
}

export default ManagePrint